<template>
  <div class="container">
    <div class="container px-md-12">
      <h1 class="my-3">Demandes <v-icon color="red">mdi-bell</v-icon></h1>

      <div class="" v-if="Requests">
        <div v-if="Requests.length > 0">
          <div v-for="(notification, i) in Requests" :key="i">
            <div class="border-t py-3 d-flex justify-space-between">
              <div>
                <div>
                  <h3>
                    Type : <span>{{ notification.type }}</span>
                  </h3>
                  <p>
                    Status : <span>{{ notification.status }}</span>
                  </p>
                  <p>
                    <span>Montant</span> :
                    <span>{{ notification.formatAmount }} FCFA</span>
                  </p>

                  <div>
                    <v-list>
                      <v-list-group
                        v-model="notification.active"
                        :prepend-icon="'mdi-cash'"
                        no-action
                      >
                        <template v-slot:activator>
                          <v-list-item-content>
                            <v-list-item-title
                              
                            > Détailles des payements standards</v-list-item-title>
                          </v-list-item-content>
                        </template>

                        <v-list-item
                          v-for="child,o in notification.standardPayments"
                          :key="o"
                        >
                          <v-list-item-content>
                              <v-row>
                                <v-col cols="3">
                                  {{ child.standardDebt.standardFees.description }}
                                </v-col>
                                <v-col cols="2">
                                  {{ functions.formatNumber(child.amount) }} FCFA
                                </v-col>
                                <v-col cols="3">
                                  {{ child.standardDebt.student.lastName }} 
                                  {{ child.standardDebt.student.firstName }} 
                                </v-col>
                                <v-col cols="2">
                                  {{ functions.getDate(child.createdAt) }} 
                                </v-col>
                                <v-col cols="2">
                                  {{ child.inBank ? 'Dépôt Banque : Oui' : 'Dépôt Banque : Non' }} 
                                </v-col>
                              </v-row>
                            <v-list-item-title
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-group>
                      <v-list-group
                        v-model="notification.secondActive"
                        :prepend-icon="'mdi-cash'"
                        no-action
                      >
                        <template v-slot:activator>
                          <v-list-item-content>
                            <v-list-item-title
                              
                            > Détailles des payements additionnels</v-list-item-title>
                          </v-list-item-content>
                        </template>

                        <v-list-item
                          v-for="child,o in notification.additionalPayments"
                          :key="o"
                        >
                          <v-list-item-content>
                              <v-row>
                                <v-col cols="3">
                                  {{ child.standardDebt.standardFees.description }}
                                </v-col>
                                <v-col cols="2">
                                  {{ functions.formatNumber(child.amount) }} FCFA
                                </v-col>
                                <v-col cols="3">
                                  {{ child.standardDebt.student.lastName }} 
                                  {{ child.standardDebt.student.firstName }} 
                                </v-col>
                                <v-col cols="2">
                                  {{ functions.getDate(child.createdAt) }} 
                                </v-col>
                                <v-col cols="2">
                                  {{ child.inBank ? 'Dépôt Banque : Oui' : 'Dépôt Banque : Non' }} 
                                </v-col>
                              </v-row>
                            <v-list-item-title
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-group>
                    </v-list>
                  </div>
                </div>
              </div>

              <div>
                <div v-if="notification.status == 'En cours'" class="d-flex">
                  <v-btn
                    color="red"
                    class="white--text"
                    :loading="loading"
                    @click="deniedRequest(notification._id)"
                    >Refusé</v-btn
                  >
                  <v-btn
                    color="green"
                    class="white--text ml-md-3 ml-2"
                    :loading="loading"
                    @click="acceptRequest(notification._id)"
                    >Accepté</v-btn
                  >
                </div>
                <!-- <span>{{ notification.updatedAt }}</span> -->
              </div>
            </div>
          </div>
        </div>
        <div v-else class="border-t pt-5">
          <p>Lorsque vous aurez des demandes , ils viendront ici .</p>
        </div>
      </div>
      <div v-else class="border-t pt-5">
        <p>Lorsque vous aurez des demandes , vous verrez ca ici .</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { defaultMethods, messages } from "../../../utils/data";
import { functions, rules } from "@/utils/constant";
import { acceptRequest, deniedRequest } from "../../../api/user";
export default {
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters(["Requests"]),

    fields() {
      return [
        {
          name: "hours",
          label: "Heures",
          type: "Number",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "teacher",
          label: "Enseignant",
          type: "Select",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            store: {
              action: this.getTeachers,
              params: {},
              getter: this.Teachers,
              fieldName: "firstName",
            },
          },
        },
        {
          name: "examIsCorrected",
          label: "Les copies d'examen sont corrigées",
          type: "Radio",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          radio: {
            data: functions.getBoolsData(),
            fieldName: "name",
            fieldValue: "value",
          },
        },
        {
          name: "ecue",
          label: "",
          type: "Static",
          value: this.$route.params.id,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
      ];
    },
    timesTablesfields() {
      return [
        {
          name: "type",
          label: "Type",
          type: "Radio",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          radio: {
            data: functions.getTimesTableTypes(),
            fieldName: "value",
            fieldValue: "value",
          },
        },

        {
          name: "duration",
          label: "Nombre d'heures",
          type: "Number",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          condition: {
            if: {
              fieldName: "exam",
              fieldValue: "course",
              operation: "=",
            },
          },
          existForAdd: true,
          rules: [rules.max(Number(this.Ecue.hourlyMass.remainingHours))],
        },
        {
          name: "date",
          label: "Date",
          type: "Date",
          col: 12,
          md: 6,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          range: false,
        },
        {
          name: "startHour",
          label: "Heure",
          type: "Time",
          col: 12,
          md: 6,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "color",
          label: "Couleur",
          type: "Color",
          col: 12,
          md: 6,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "hall",
          label: "Salle",
          type: "Select",
          col: 12,
          md: 6,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            store: {
              action: this.getHalls,
              params: {},
              getter: this.Halls,
              fieldName: "name",
            },
          },
        },
        {
          name: "hourlyMass",
          label: "",
          type: "Static",
          value:
            this.Ecue && this.Ecue.hourlyMass ? this.Ecue.hourlyMass._id : "",
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
      ];
    },
  },
  data() {
    return {
      loading: false,
      functions
    };
  },
  watch: {},
  methods: {
    ...mapActions(["getRequests"]),
    closeForm() {
      this.Model = {};
      this.editorDialog = false;
      this.editedIndex = -1;
    },
    async deniedRequest(id) {
      this.loading = true;
      try {
        await deniedRequest({
          id: id,
        });
        this.init();
      } catch (error) {
        defaultMethods.dispatchError(this.$store);
      }
      this.loading = false;
    },
    async acceptRequest(id) {
      this.loading = true;
      try {
        await acceptRequest({
          id: id,
        });
        this.init();
      } catch (error) {
        defaultMethods.dispatchError(this.$store);
      }
      this.loading = false;
    },

    async init() {
      this.loading = true;
      try {
        await this.getRequests({ addressToMe: true });
      } catch (error) {
        defaultMethods.dispatchError(
          this.$store,
          messages.failedToLoad("les demandes")
        );
      }
      this.loading = false;
    },
  },
};
</script>

<style>
</style>